import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './lang/en.json';
import translationFR from './lang/fr.json';
import translationRU from './lang/ru.json';

const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  ru: {
    translation: translationRU
  }
};

i18n.use(initReactI18next)
    .init({
      resources,
      lng: 'fr',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });

export default i18n;
