import React from 'react';
import { useState, useEffect } from 'react';
import {Routes, Route, Outlet, Link} from 'react-router-dom';
import './App.css';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
// Images
import schoolLogo from "./assets/img/Logo_FR_scrap.png";
import BackgroundImage from './assets/img/bgparis.jpg';
import RDGaspard from './assets/img/RDG.jpg';
import PTE_logo from './assets/img/PTE_logo.jpg';
// Flag lang Images
import ukFlag from './assets/img/ukflag.gif';
import frFlag from './assets/img/frflag.gif';
import ruFlag from './assets/img/ruflag.gif';
// PDFs
/// Common
import pdfSurgeryFR from './assets/common_docs/autorisation_d-intervention_chirurgicale.pdf';
import pdfOutFR from './assets/common_docs/autorisation_sorties.pdf';
import pdfTakingCareFR from './assets/common_docs/autorisation_prise_en_charge.pdf';
import pdfRulesFR from './assets/common_docs/reglement_de_lecole_fr.pdf';
import pdfRulesEN from './assets/common_docs/reglement_de_lecole_en.pdf';
import pdfMedicalFR from './assets/common_docs/renseignements_medicaux.pdf';
import pdfCalendarSchoolFR from './assets/common_docs/calendrier_scolaire_fr.pdf';
import pdfCalendarSchoolEN from './assets/common_docs/calendrier_scolaire_en.pdf';
/// Nursery PreSchool
import pdfNurserySchoolGarderieApplication from './assets/nursery_school_docs/bulletin_inscription_garderie_mater.pdf';
import pdfNurserySchoolApplication from './assets/nursery_school_docs/bulletin_inscription.pdf';
import pdfNurserySchoolContrat from './assets/nursery_school_docs/contrat_ecole.pdf';
import pdfNurserySchoolFees from './assets/nursery_school_docs/tarifs_mater.pdf';
/// Primary School
import pdfPrimarySchoolGarderieApplication from './assets/primary_school_docs/bulletin_inscription_garderie_etude_primaires.pdf';
import pdfPrimarySchoolApplication from './assets/primary_school_docs/bulletin_inscription.pdf';
import pdfPrimarySchoolContrat from './assets/primary_school_docs/contrat_ecole.pdf';
import pdfPrimarySchoolFees from './assets/primary_school_docs/tarifs_prim.pdf';
/// Wednesday School Workshops
import pdfWorkShopWednesdaySchoolApplication from './assets/wednesday_workshop_docs/bulletin_inscription_wed.pdf';
import pdfWorkShopWednesdaySchoolContratFR from './assets/wednesday_workshop_docs/contrat_mercredi_fr.pdf';
import pdfWorkShopWednesdaySchoolContratEN from './assets/wednesday_workshop_docs/contrat_mercredi_en.pdf';
import pdfWorkShopWednesdaySchoolFees from './assets/wednesday_workshop_docs/tarifs_wed.pdf';
import pdfWorkShopWednesdaySchoolGarderieApplication from './assets/wednesday_workshop_docs/bulletin_inscription_garderie_mercredi.pdf';
/// Holidays
import pdfHolidayWorkshopConditionAutomn from './assets/holidays_docs/ateliers_automne_conditions.pdf';
import pdfHolidayWorkshopConditionSummer from './assets/holidays_docs/ateliers_ete_conditions.pdf';
import pdfHolidayWorkshopConditionWinter from './assets/holidays_docs/ateliers_hiver_conditions.pdf';
import pdfHolidayWorkshopConditionSpring from './assets/holidays_docs/ateliers_printemps_conditions.pdf';
import pdfHolidayWorkshopApplication from './assets/holidays_docs/bulletin_stage.pdf';
import pdfHolidaysCalendar from './assets/holidays_docs/Calendrier_des_ateliers_de_vacances.pdf';

const CenterText = {
  textAlign: 'center',
};

const LeftText = {
  textAlign: 'left',
};

const ULLeftStyle = {
  textAlign: 'left',
};

function PC(props) {
  return <p style={CenterText}>{props.children}</p>;
}

function PL(props) {
  return <p style={LeftText}>{props.children}</p>;
}

function ULLeft(props) {
  return <ul style={ULLeftStyle}>{props.children}</ul>;
}

function Testimony(props) {
  return (
    <p>
      <i>
        {props.children}
      </i>
    </p>
  );
}

const BG = {
  margin: 'auto',
  maxWidth: '1600px',
  width: '100%',
  background: `url(${BackgroundImage}) center top no-repeat`,
  backgroundSize: 'contain',
  backgroundAttachment: "scroll",
  /*
  maxWidth: '1600px',
  margin: '0 auto',
  backgroundColor: "#fff",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: 'center',
  backgroundAttachment: "scroll",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center top",
  backgroundSize: 'cover',
  */
};

const MenuStyle = {
  margin: "0px 50px 0px 50px",
  padding: "10px 0px 0px 10px",
  display: 'flex',
  flexDirection: 'row',
  alignIrmes: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#ffffffe5',
  borderRadius: '10px 10px 0 0',
  overflow: 'hidden',
  borderBottom: '3px solid #2A3B94',
  ':hover': {
    backgroundColor: '#4CAF50',
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
};

const MenuNotWideStyle = {
  margin: "0px 50px 0px 50px",
  display: 'flex',
  flexDirection: 'column',
  alignIrmes: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#ffffffe5',
  borderRadius: '10px 10px 0 0',
  overflow: 'hidden',
  fontSize: '13px',
  borderBottom: '1px solid #2A3B94',
  ':hover': {
    backgroundColor: '#4CAF50',
    cursor: 'pointer',
  },
};

const FooterStyle = {
  paddingTop: 35,
  textAlign: 'center',
};

const OutletStyle = {
  margin: "0px 50px 0px 50px",
  backgroundColor: '#ffffffa5',
  borderBottom: '2px solid #2A3B94',
  //borderLeft: '2px solid #2A3B94',
  //borderRight: '2px solid #2A3B94',
  borderRadius: '0px 0px 10px 10px',
};

const LangBasicStyle = {
  paddingBottom: '1px',
  marginRight: '5px',
};

const LangSelectedStyle = {
  borderBottom: '3px solid #2A3B94',
  paddingBottom: '2px',
  marginRight: '5px',
};

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <div className="BackGround" style={BG}>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Menu />}>
            <Route index element={<School />} />
            <Route path="/school" element={<School />} />
            <Route path="/program" element={<Program />} />
            <Route path="/extra-activities" element={<ExtraActivities />} />
            <Route path="/after-cm2" element={<AfterCM2 />} />
            <Route path="/admissions" element={<Admissions />} />
            <Route path="/documents" element={<Documents />} />
            {/* <Route path="/location" element={<HowToGetThere />} /> */}
            <Route path="/partners" element={<Partners />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      <Footer />
      </div>
      </div>
    </I18nextProvider>
  );
}

function ErrorPage() {
  return (
    <>
      <h1>Nothing here!</h1>
      <p>Sorry, it seems, this page is empty.</p>
    </>
  );
}

function Header() {
  const { t } = useTranslation();
  return (
    <div className="Header">
      <LanguageSwitcher />
      <img
        src={schoolLogo}
        width="250"
        alt={t('schoolname')}
        style={{
          borderWidth: "4rem",
          borderColor: "#244c90",
          borderRadius: "20px",
        }}
      />
      {/*<h1>{t('schoolname')}</h1>*/}
          </div>
  );
}

function createMenuItem(linkData, t) {
  // t: translastion
  let textKey = linkData.text;
  // handle link to external website, define as href in the linkData, url is for this website Router
  if (linkData.href) {
    return (
      <div
        className="nav-link"
        key={textKey}
      >
        <a href={linkData.href}>
          {t(textKey)}
        </a>
      </div>
    );
  }
  let linkUrl = linkData.url;
  return (
      <Link
        key={textKey}
        className="nav-link"
        activeClassName="active"
        to={linkUrl}
      >
      {t(textKey)}
      </Link>
  );
}

function Menu() {
  const { t } = useTranslation();
  // Actualite and blog => moved to Google Classroom
  const links = [
    { url: '/school', text: 'menu.school' },
    { url: '/program', text: 'menu.programs' },
    // DEPRECATED { href: "http://petiteecolebilingue.free.fr/site/paris/fr/primaire.php", text: 'menu.programs'},
    { url: '/extra-activities', text: 'menu.extraschool' },
    // DEPRECATED { href: "http://petiteecolebilingue.free.fr/site/paris/fr/extrascol.php", text: 'menu.extraschool'},
    { url: '/after-cm2', text: 'menu.aftercm2' },
    // DEPRECATED : { href: "http://petiteecolebilingue.free.fr/site/paris/fr/apres.php", text: 'menu.aftercm2'},
    { url: '/admissions', text: 'menu.admissions' },
    // DEPRECATED ; TO RM if okay { href: "http://petiteecolebilingue.free.fr/site/paris/fr/admission.php", text: 'menu.admissions' },
    { url: '/documents', text: 'menu.documents' },
    { url: '/partners', text: 'menu.partners' },
    { url: '/contact', text: 'menu.contact' },
  ];

  // Set initial state for dropdown menu
  const [wide, setWide] = useState(window.innerWidth > 1600);

  // Check screen width and toggle dropdown menu state
  function handleResize() {
    if (window.innerWidth > 1600) {
      setWide(true);
    } else {
      setWide(false);
    }
  }

  // Add event listener for screen resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  function generateWideMenu(t, links) {
    return (
      <>
      {links.map((link_data, index) => (
        createMenuItem(link_data, t)
      ))}
      </>
    );
  }

  return (
    <>
      <nav className="Menu" style={wide ? MenuStyle : MenuNotWideStyle}>
        {/* Wide Screen */}
        {generateWideMenu(t, links)}
        {/* Wide Menu End */}
      </nav>
      {/* This is the actual position of the dynamic object "page" */}
      <div style={OutletStyle}>
        <Outlet style={OutletStyle}/>
      </div>
    </>
  );
}

function Footer() {
  const { t } = useTranslation();
  return (
    <div style={FooterStyle} className="Footer">
      <PC>
        Etablissement privé hors contrat<br />
        <b>Ecole bilingue, Anglais-Français ou Anglais-Français-Russe, de 2 ans au CM2</b>
        <br />
          8, Place de la Porte de Champerret 75017 Paris
        <br />
          Tel. : 01 43 80 25 34
        <br />
          {/* TODO check content in contact and redirect to Contact page of this site */}
          {/* TODO Move href to a local site LEGAL page <Legal /> */}
          <Link
            className="nav-link"
            activeClassName="active"
            to={'/contact'}
          >
            contact@stewartintschool.com
          </Link>
          {' - '}
          <Link
            className="nav-link"
            activeClassName="active"
            to={'/legal'}
          >
            {t("legal.title")}
          </Link>
      </PC>
    </div>
  );
}

function Home() {
  return (
    <>
      HOME
    </>
  );
}


function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLangi18n = (lang) => {
    i18n.changeLanguage(lang);
  }

  const handleLanguageChangeUK = (event) => {
    changeLangi18n('en');
  };
  const handleLanguageChangeFR = (event) => {
    changeLangi18n('fr');
  };
  const handleLanguageChangeRU = (event) => {
    changeLangi18n('ru');
  };

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start'}}>
      <img src={ukFlag} alt={'English'} onClick={handleLanguageChangeUK} style={i18n.language === "en" ? LangSelectedStyle : LangBasicStyle} />
      <img src={frFlag} alt={'Français'} onClick={handleLanguageChangeFR} style={i18n.language === "fr" ? LangSelectedStyle : LangBasicStyle} />
      <img src={ruFlag} alt={'Русский'} onClick={handleLanguageChangeRU} style={i18n.language === "ru" ? LangSelectedStyle : LangBasicStyle} />
    </div>
  );
}


function Partners() {
  const { t } = useTranslation();
  return (
    <div>
      <tbody>
        <tr>
          <td class="logo">
            <h3>
              <img src={RDGaspard} width="300" alt="Le Répertoire de Gaspard" />
            </h3>
          </td>
          <td>
            <p>
              {t("partners.repertoiredegaspard.1")}
            </p>
            <p>
              {t("partners.repertoiredegaspard.2")}
            </p>
            <p>
              {t("partners.repertoiredegaspard.3")}
          </p>
          <a href="http://www.lerepertoiredegaspard.com" alt="Le Répertoire de Gaspard">www.lerepertoiredegaspard.com</a> – 01 83 87 20 55
          <p>
            <br />
            {t("partners.repertoiredegaspard.contact.fr")}
            <br />
            {t("partners.repertoiredegaspard.contact.en")}
          </p>
        </td>
      </tr>
      <tr>
        <td>
          &nbsp;
        </td>
        <td>
          &nbsp;
        </td>
      </tr>
      <tr>
        <td class="logo">
          <h3><img 
          width="250"
          src={PTE_logo} alt="PTE Young Learners"/></h3>
        </td>
        <td>
          <p>
          PTE Young Learners est une série d'examens Pearson visant principalement les enfants âgés de 7 à 12 ans. Les test sont conçus de manière à intéresser et amuser les enfants et ont pour but de donner une première expérience motivante et mémorable de l'apprentissage de l'anglais. Les 4 niveaux existants consistent à tester les enfants sur leur capacité à gérer les structures et les fonctions dans des contextes réalistes et, petit à petit, sur leur capacité à utiliser le langage pour des tâches spécifiques de communication. Tous les enfants ayant réussi le test reçoivent un certificat international officiel.
          </p>
          <p>
            <a href="http://pearsonpte.com/PTEYoungLearners/"   alt="PTE Young Learners">Pearson Test of English Young Learners</a></p></td></tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td class="logo">
          <h3>
            <img
              width="250"
              src="http://petiteecolebilingue.free.fr/site/_media/paris/partenaires/luniforme_bobine.gif" alt="L'Uniforme Bobine" />
          </h3>
          </td>
                              <td>
                                <b>COMMANDES UNIFORMES</b>
                                <p>Les parents doivent commander la tenue sur le site internet du fournisseur indiqué ci-dessous.</p> <p>School Uniform<br /> La société Bobine est créateur et fabricant de prêt à porter pour enfants et de tabliers d’école depuis plus de 25 ans et a élargi son offre sur l’uniforme scolaire il y a maintenant 2 ans.<br />Les productions sont réalisées dans des ateliers espagnols, portugais et franco malgaches de toute confiance avec qui Bobine collabore depuis de nombreuses années.<br />Aujourd’hui, plus de 100 établissements scolaires font confiance à Bobine qui met tout en œuvre pour équiper leurs élèves avec des produits de qualité tout au long de l’année .</p>
              <p>
                <a href="https://www.letablierbobine.fr/produits?school=stewart-international#product-list" alt="Visit / Order">Commander sur l'espace de l'école - Stewart International School</a>
              </p>
          </td>
        </tr>
      </tbody>
    </div>
  )
}


function School() {
  const { t } = useTranslation();
  return (
    <div className="School">
      <h3>
        {t("school.title")}
      </h3>
      <h4>
        {t("school.history.title")}
      </h4>
      <PL>
        {t("school.history.1")}
      </PL>
      <PL>
        {t("school.history.2")}
      </PL>
      <PL>
        {t("school.history.3")}
      </PL>
      <PL>
        {t("school.history.4")}
      </PL>
    </div>
  );
}

function Program() {
  const { t } = useTranslation();
  const [mode, setMode] = useState("primary");
  const change_mode_nursery = (event) => {
    setMode("nursery");
  };
  const change_mode_primary = (event) => {
    setMode("primary");
  };
  function ProgramTabs(props) {
    return (
      <ul className="ProgramHeaderUL">
        <li>
          <button className="ButtonProgram" onClick={props.change_mode_nursery}>
            {t("programs.nursery")}
          </button>
        </li>
        <li>
          <button className="ButtonProgram" onClick={props.change_mode_primary}>
            {t("programs.primary")}
          </button>
        </li>
      </ul>
    );
  }
  if (mode === "nursery") {
    return (
      <div className="Program">
        <ProgramTabs change_mode_nursery={change_mode_nursery} change_mode_primary={change_mode_primary} />
        <h2>{t("programs.nursery")}</h2>
        <h3>{t("programs.nursery.introduction")}</h3>
        <PL>{t("programs.nursery.introduction.1")}</PL>
        <PL>{t("programs.nursery.introduction.2")}</PL>
        <PL>{t("programs.nursery.introduction.3")}</PL>
        <PL>{t("programs.nursery.introduction.4")}</PL>
        <PL>{t("programs.nursery.introduction.5")}</PL>
        <ULLeft>
          <li>{t("programs.nursery.introduction.ul.1")}</li>
          <li>{t("programs.nursery.introduction.ul.2")}</li>
          <li>{t("programs.nursery.introduction.ul.3")}</li>
          <li>{t("programs.nursery.introduction.ul.4")}</li>
          <li>{t("programs.nursery.introduction.ul.5")}</li>
          <li>{t("programs.nursery.introduction.ul.6")}</li>
          <li>{t("programs.nursery.introduction.ul.7")}</li>
          <li>{t("programs.nursery.introduction.ul.8")}</li>
          <li>{t("programs.nursery.introduction.ul.9")}</li>
          <li>{t("programs.nursery.introduction.ul.10")}</li>
        </ULLeft>
        <PL>{t("programs.nursery.introduction.6")}</PL>
        <h3>{t("programs.nursery.pedagogy")}</h3>
        <PL>{t("programs.nursery.pedagogy.1")}</PL>
        <PL>{t("programs.nursery.pedagogy.2")}</PL>
        <PL>{t("programs.nursery.pedagogy.3")}</PL>
        <PL>{t("programs.nursery.pedagogy.4")}</PL>
        <h3>{t("programs.nursery.activities")}</h3>
        <PL>{t("programs.nursery.activities.intro")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.1")}</h4>
        <PL>{t("programs.nursery.activities.text.1")}</PL>
        <PL>{t("programs.nursery.activities.text.2")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.2")}</h4>
        <PL>{t("programs.nursery.activities.text.3")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.3")}</h4>
        <PL>{t("programs.nursery.activities.text.4")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.4")}</h4>
        <PL>{t("programs.nursery.activities.text.5")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.5")}</h4>
        <PL>{t("programs.nursery.activities.text.6")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.6")}</h4>
        <PL>{t("programs.nursery.activities.text.7")}</PL>
        <h4>{t("programs.nursery.activities.subtitle.7")}</h4>
        <PL>{t("programs.nursery.activities.text.8")}</PL>
       </div>
    );
  } else {
    return (
      <div className="Program">
        <ProgramTabs change_mode_nursery={change_mode_nursery} change_mode_primary={change_mode_primary} />
        <h2>{t("programs.primary")}</h2>
        <h3>{t("programs.primary.introduction")}</h3>
        <PL>{t("programs.primary.introduction.1")}</PL>
        <PL>{t("programs.primary.introduction.2")}</PL>
        <PL>{t("programs.primary.introduction.3")}</PL>
        <PL>{t("programs.primary.introduction.4")}</PL>
        <PL>{t("programs.primary.introduction.5")}</PL>
        <PL>{t("programs.primary.introduction.6")}</PL>
        <ULLeft>
          <li>{t("programs.primary.introduction.ul.1")}</li>
          <li>{t("programs.primary.introduction.ul.2")}</li>
          <li>{t("programs.primary.introduction.ul.3")}</li>
          <li>{t("programs.primary.introduction.ul.4")}</li>
          <li>{t("programs.primary.introduction.ul.5")}</li>
          <li>{t("programs.primary.introduction.ul.6")}</li>
          <li>{t("programs.primary.introduction.ul.7")}</li>
          <li>{t("programs.primary.introduction.ul.8")}</li>
          <li>{t("programs.primary.introduction.ul.9")}</li>
          <li>{t("programs.primary.introduction.ul.10")}</li>
          {t("programs.primary.introduction.ul.11") === " " ? null :  <li>{t("programs.primary.introduction.ul.11")}</li>}
        </ULLeft>
        <PL>{t("programs.primary.introduction.7")}</PL>
        <h3>{t("programs.primary.pedagogy")}</h3>
        <PL>{t("programs.primary.pedagogy.1")}</PL>
        <PL>{t("programs.primary.pedagogy.2")}</PL>
        <PL>{t("programs.primary.pedagogy.3")}</PL>
        <PL>{t("programs.primary.pedagogy.4")}</PL>
        <PL>{t("programs.primary.pedagogy.5")}</PL>
        <PL>{t("programs.primary.pedagogy.6")}</PL>
        <PL>{t("programs.primary.pedagogy.7")}</PL>
        <PL>{t("programs.primary.pedagogy.8")}</PL>
        <PL>{t("programs.primary.pedagogy.9")}</PL>
        <h3>{t("programs.primary.activities")}</h3>
        <PL>{t("programs.primary.activities.intro")}</PL>
        <h4>{t("programs.primary.activities.subtitle.1")}</h4>
        <PL>{t("programs.primary.activities.text.1")}</PL>
        <PL>{t("programs.primary.activities.text.2")}</PL>
        <h4>{t("programs.primary.activities.subtitle.2")}</h4>
        <PL>{t("programs.primary.activities.text.3")}</PL>
        <h4>{t("programs.primary.activities.subtitle.3")}</h4>
        <PL>{t("programs.primary.activities.text.4")}</PL>
        <h4>{t("programs.primary.activities.subtitle.4")}</h4>
        <PL>{t("programs.primary.activities.text.5")}</PL>
        <h4>{t("programs.primary.activities.subtitle.5")}</h4>
        <PL>{t("programs.primary.activities.text.6")}</PL>
        <h4>{t("programs.primary.activities.subtitle.6")}</h4>
        <PL>{t("programs.primary.activities.text.7")}</PL>
        <h4>{t("programs.primary.activities.subtitle.7")}</h4>
        <PL>{t("programs.primary.activities.text.8")}</PL>
        <h4>{t("programs.primary.activities.subtitle.8")}</h4>
        <PL>{t("programs.primary.activities.text.9")}</PL>
      </div>
    );
  }
}

function ExtraActivities() {
  const { t } = useTranslation();
  return (
    <div className="ExtraAct">
      <h3>
        {t("extraactivities.title")}
      </h3>
      <h4>
        {t("extraactivities.subtitle.1")}
      </h4>
      <PL>
        {t("extraactivities.1")}
      </PL>
      <PL>
        {t("extraactivities.2")}
      </PL>
      <PL>
        {t("extraactivities.3")}
      </PL>
      <h4>
        {t("extraactivities.subtitle.1")}
      </h4>
      <PL>
        {t("extraactivities.4")}
      </PL>
    </div>
  );
}

function AfterCM2() {
  const { t } = useTranslation();
  return (
    <div className="AfterCM2">
      <h3>
        {t("aftercm2.title")}
      </h3>
      <h4>
        {t("aftercm2.orientation")}
      </h4>
      <PL>
        {t("aftercm2.textintro")}
      </PL>
      <PL>
        {t("aftercm2.stateschool.ul")}
      </PL>
      <ULLeft>
        <li>
          {t("aftercm2.stateschool.ul.6eme")}
        </li>
        <li>
          {t("aftercm2.stateschool.ul.intersection")}
        </li>
        <li>
          {t("aftercm2.stateschool.ul.bilingualsection")}
        </li>
        <li>
          {t("aftercm2.stateschool.ul.sportssection")}
        </li>
        <li>
          {t("aftercm2.stateschool.ul.musicsection")}
        </li>
      </ULLeft>

      <PL>
        {t("aftercm2.privateschool.ul")}
      </PL>
      <ULLeft>
        <li>
          {t("aftercm2.privateschool.ul.catholic")}
        </li>
        <li>
          {t("aftercm2.privateschool.ul.catholicwithenglish")}
        </li>
        <li>
          {t("aftercm2.privateschool.ul.bilingualsection")}
        </li>
        <li>
          {t("aftercm2.privateschool.ul.other")}
        </li>
      </ULLeft>

      <PL>
        {t("aftercm2.privateschooloffcontract.ul")}
      </PL>
      <ULLeft>
        <li>
          {t("aftercm2.privateschooloffcontract.ul.6eme")}
        </li>
        <li>
          {t("aftercm2.privateschooloffcontract.ul.bilingualsection")}
        </li>
      </ULLeft>

      <h4>
        {t("aftercm2.inscription.title")}
      </h4>
      <PL>
        {t("aftercm2.inscription.text.1")}
      </PL>
      <PL>
        {t("aftercm2.inscription.text.2")}
      </PL>
      <PL>
        {t("aftercm2.inscription.text.3")}
      </PL>
      <PL>
        {t("aftercm2.inscription.text.4")}
      </PL>

      <h4>
        {t("aftercm2.establishments.title")}
      </h4>
      <PL>
        {t("aftercm2.establishments.intro")}
      </PL>

      <ULLeft>
        <li>
          {t("aftercm2.establishments.ul.1")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.2")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.3")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.4")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.5")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.6")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.7")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.8")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.9")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.10")}
        </li>
        <li>
          {t("aftercm2.establishments.ul.11")}
        </li>
      </ULLeft>

      <PL>
        {t("aftercm2.establishments.stats.intro")}
      </PL>

      <table width="auto" border="0" cellspacing="0" cellpadding="0" class="table-open-hours">
        <tbody>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.title")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.title")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l1")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l1")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l2")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l2")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l3")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l3")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l4")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l4")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l5")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l5")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l6")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l6")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l7")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l7")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l8")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l8")}</td>
          </tr>
          <tr>
            <td>{t("aftercm2.establishments.stats.tr.col1.l9")}</td>
            <td>{t("aftercm2.establishments.stats.tr.col2.l9")}</td>
          </tr>
        </tbody>
      </table>

      <h4>
        {t("aftercm2.testimonies.title")}
      </h4>
      <PL>
        {t("aftercm2.testimonies.intro")}
      </PL>
      <Testimony>{t("aftercm2.testimonies.1")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.2")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.3")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.4")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.5")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.6")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.7")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.8")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.9")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.10")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.11")}</Testimony>
      <Testimony>{t("aftercm2.testimonies.12")}</Testimony>
      <PL>
        <b>
          {t("aftercm2.testimonies.outro")}
        </b>
      </PL>
    </div>
  );
}

function Admissions() {
  const { t } = useTranslation();
  return (
    <div className="Admissions">
      <h3>
        {t("admission.title")}
      </h3>
      <PL>
        {t("admission.1")}
      </PL>
      <h4>
        {t("admission.subtitle.1")}
      </h4>
      <PL>
        {t("admission.2")}
      </PL>
      <PL>
        <b>{t("admission.3")}</b>
      </PL>
      {/* Table */}
      <table width="auto" border="0" cellspacing="0" cellpadding="0" class="table-open-hours">
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td>{t("admission.table.col1.ln0")}</td>
            <td>{t("admission.table.col2.ln0")}</td>
          </tr>
          <tr>
            <td>{t("admission.table.col0.ln1")}</td>
            <td>{t("admission.table.col1.ln1")}</td>
            <td>{t("admission.table.col2.ln1")}</td>
          </tr>
          <tr>
            <td>{t("admission.table.col0.ln2")}</td>
            <td>{t("admission.table.col1.ln2")}</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <h4>
        {t("admission.subtitle.rdv")}
      </h4>
      <ULLeft>
        <li>
          {t("admission.rdv.list.1")}
        </li>
        <li>
          {t("admission.rdv.list.2")}
        </li>
        <li>
          {t("admission.rdv.list.3")}
        </li>
      </ULLeft>
      <h4>
        <u>
          {t("admission.subtitle.foreign")}
        </u>
      </h4>
      <ULLeft>
        <li>
          {t("admission.foreign.list.1")}
        </li>
        <li>
          {t("admission.foreign.list.2")}
        </li>
      </ULLeft>
      <h4>
        {t("admission.subtitle.registration")}
      </h4>
      <PL>
        {t("admission.registration.text.prelink")}
        <Link
          key={"admission.registration.text.link"}
          className="nav-link"
          activeClassName="active"
          to={'/documents'}
        >
        {t("admission.registration.text.link")}
        </Link>
        {t("admission.registration.text.postlink")}
      </PL>
      <ULLeft>
        <li>
          {t("admission.registration.dl.application")}
        </li>
        <li>
          {t("admission.registration.dl.contract")}
        </li>
        <li>
          {t("admission.registration.dl.prise")}
        </li>
        <li>
          {t("admission.registration.dl.outings")}
        </li>
        <li>
          {t("admission.registration.dl.medical")}
        </li>
        <li>
          {t("admission.registration.dl.surgery")}
        </li>
        <li>
          {t("admission.registration.list.photo")}
        </li>
        <li>
          {t("admission.registration.list.home")}
        </li>
        <li>
          {t("admission.registration.list.vaccin")}
        </li>
        <li>
          {t("admission.registration.list.RIB")}
        </li>
        <li>
          {t("admission.registration.list.respocivile")}
        </li>
        <li>
          {t("admission.registration.list.schoollivret")}
        </li>
      </ULLeft>
      <PL>
        {t("admission.end.1")}
      </PL>
      <PL>
        {t("admission.end.2")}
      </PL>
      <PL>
        {t("admission.end.3")}
      </PL>
      <PL>
        {t("admission.end.4")}
      </PL>
    </div>
  );
}

function Documents() {
  const { t } = useTranslation();

  function downloadPDFGen(pdf, pdf_name) {
    const downloadPDF = () => {
      const link = document.createElement('a');
      link.href = pdf;
      link.download = pdf_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    return downloadPDF;
  }

  // Documents Menu fast track (Order of Docs)
  // Commun
  // 1 Reglement Ecole
  // 2 Renseingment Medicaux
  // 3 Autorisation Chirurgicale
  // 4 Autorisation de sortie
  // 5 Autorisation de prise en charge
  // 6 Calendrier Scolaire
  // Ecole Maternelle
  // 1 Tarifs
  // 2 Bulletin d'inscription
  // 3 Contracts
  // 4 Bulletin d'inscription à la garderie
  // Ecole Primaire
  // 1 Tarifs
  // 2 Bulletin d'inscription
  // 3 Contracts
  // 4 Bulletin d'inscription à l'étude et la garderie
  // Atelier du Mercredi
  // 1 Tarifs
  // 2 Bulletin d'inscription
  // 3 Contract
  // 4 Bulletin d'inscription à la garderie
  // Ateliers Vacances
  // 1 Calendrier des Ateliers Vacances
  // 2 Bulletin d'inscription aux atelier vacances
  // 3 Conditions ateliers automnes
  // 4 Conditions ateliers printemps
  // 5 Conditions ateliers été

  function generate_doc_entry(lang, text, document_pdf, document_name) {
    return (
      <li>
        <button className="Button" onClick={downloadPDFGen(document_pdf, document_name)}>
          <p className="DocumentsP">{text}</p>
        </button>
      </li>
    );
  }

  const lang = i18n.language;
  return (
    <div>
      {/*
      <ul className="DocumentsUL">
        <li>
          <a href="#documents_commun">{t("document.commun")}</a>
        </li>
        <li>
          <a href="#documents_nursery">{t("document.nurseryschool")}</a>
        </li>
        <li>
          <a href="#documents_school">{t("document.school")}</a>
        </li>
        <li>
          <a href="#documents_workshop">{t("document.workshopwednesday")}</a>
        </li>
        <li>
          <a href="#documents_holidays">{t("document.holidays")}</a>
        </li>
      </ul>
      */}
      <p style={{textAlign: "left", marginLeft: '10px'}}>{t("document.dl.explain")}</p>
      <h3 id={"document_commun"}>{t("document.commun")}</h3>
      <ul className="DocumentsUL">
        {(lang === "fr") ? 
            generate_doc_entry(lang, t('document.commun.schoolrule'), pdfRulesFR, "reglement_de_lecole_fr.pdf") :
            generate_doc_entry(lang, t('document.commun.schoolrule'), pdfRulesEN, "reglement_de_lecole_en.pdf")}
        {generate_doc_entry(lang, t('document.commun.medical'), pdfMedicalFR, "renseignements_medicaux.pdf")}
        {generate_doc_entry(lang, t('document.commun.surgical'), pdfSurgeryFR, "autorisation_intervention_chirurgicale.pdf")}
        {generate_doc_entry(lang, t('document.commun.outings'), pdfOutFR, "autorisation_sorties.pdf")}
        {generate_doc_entry(lang, t('document.commun.charge'), pdfTakingCareFR, "autorisation_prise_en_charge.pdf")}
        {(lang === "fr") ? 
        generate_doc_entry(lang, t('document.commun.calendar'), pdfCalendarSchoolFR, "calendrier_scolaire_fr.pdf") :
        generate_doc_entry(lang, t('document.commun.calendar'), pdfCalendarSchoolEN, "calendrier_scolaire_en.pdf")}
      </ul>
      <h3 id={"documents_nursery"}>{t("document.nurseryschool")}</h3>
      <ul className="DocumentsUL">
        {generate_doc_entry(lang, t('document.nurseryschool.fees'), pdfNurserySchoolFees, "tarifs_mater.pdf")}
        {generate_doc_entry(lang, t('document.nurseryschool.application'), pdfNurserySchoolApplication, "bulletin_inscription.pdf")}
        {generate_doc_entry(lang, t('document.nurseryschool.contract'), pdfNurserySchoolContrat, "contrat_ecole.pdf")}
        {generate_doc_entry(lang, t('document.nurseryschool.nursery'), pdfNurserySchoolGarderieApplication, "bulletin_inscription_garderie_mater.pdf")}
      </ul>
      <h3 id={"documents_school"}>{t("document.school")}</h3>
      <ul className="DocumentsUL">
        {generate_doc_entry(lang, t('document.school.fees'), pdfPrimarySchoolFees, "tarifs_prim.pdf")}
        {generate_doc_entry(lang, t('document.school.application'), pdfPrimarySchoolApplication, "bulletin_inscription.pdf")}
        {generate_doc_entry(lang, t('document.school.contract'), pdfPrimarySchoolContrat, "contrat_ecole.pdf")}
        {generate_doc_entry(lang, t('document.school.nursery'), pdfPrimarySchoolGarderieApplication, "bulletin_inscription_garderie_etude.pdf")}
      </ul>
      <h3 id={"documents_workshop"}>{t("document.workshopwednesday")}</h3>
      <ul className="DocumentsUL">
        {generate_doc_entry(lang, t('document.workshopwednesday.fees'), pdfWorkShopWednesdaySchoolFees, "tarifs_wed.pdf")}
        {generate_doc_entry(lang, t('document.workshopwednesday.application'), pdfWorkShopWednesdaySchoolApplication, "bulletin_inscription.pdf")}
        {(lang === "fr") ? 
            generate_doc_entry(lang, t('document.workshopwednesday.contract'), pdfWorkShopWednesdaySchoolContratFR, "contrat_wed_fr.pdf") :
            generate_doc_entry(lang, t('document.workshopwednesday.contract'), pdfWorkShopWednesdaySchoolContratEN, "contrat_wed_en.pdf")}
        {generate_doc_entry(lang, t('document.workshopwednesday.nursery'), pdfWorkShopWednesdaySchoolGarderieApplication, "bulletin_inscription_garderie_wed.pdf")}
      </ul>
      <h3 id={"documents_holidays"}>{t("document.holidays")}</h3>
      <ul className="DocumentsUL">
        {generate_doc_entry(lang, t('document.holidays.calendar'), pdfHolidaysCalendar, "Calendrier_des_ateliers_de_vacances.pdf")}
        {generate_doc_entry(lang, t('document.holidays.application'), pdfHolidayWorkshopApplication, "bulletin_inscription_stage.pdf")}
        {generate_doc_entry(lang, t('document.holidays.automn'), pdfHolidayWorkshopConditionAutomn, "ateliers_automne_conditions.pdf")}
        {generate_doc_entry(lang, t('document.holidays.winter'), pdfHolidayWorkshopConditionWinter, "ateliers_hiver_conditions.pdf")}
        {generate_doc_entry(lang, t('document.holidays.spring'), pdfHolidayWorkshopConditionSpring, "ateliers_printemps_conditions.pdf")}
        {generate_doc_entry(lang, t('document.holidays.summer'), pdfHolidayWorkshopConditionSummer, "ateliers_ete_conditions.pdf")}
       </ul>
    </div>
  );
}

function Legal() {
  const { t } = useTranslation();
  return (
    <div className="Legal">
      <h3>{t("legal.title")}</h3>
      <PL>{t("legal.text.1")}</PL>
      <PL>{t("legal.text.2")}</PL>
      <PL>{t("legal.text.3")}</PL>
      <PL>{t("legal.text.4")}</PL>
      <PL>{t("legal.text.5")}</PL>
      <PL>{t("legal.text.6")}</PL>
      <h4>{t("legal.editor")}</h4>
      <PC>{t("legal.editor.text.1")}</PC>
      <PC>{t("legal.editor.text.2")}</PC>
      <PC>{t("legal.editor.text.3")}</PC>
      <PC>{t("legal.editor.text.4")}</PC>
      <h4>{t("legal.com")}</h4>
      <PC>{t("legal.com.person")}</PC>
      <PC>{t("legal.com.email")}</PC>
      <h4>{t("legal.host")}</h4>
      <PC>{t("legal.host.text")}</PC>
      <h4>{t("legal.dev")}</h4>
      <PC><a href="http://www.bluepact.com/">{t("legal.dev.bluepact")}</a></PC>
      <PC><a href="http://www.sidohorizon.com/">{t("legal.dev.sidohorizon")}</a></PC>
    </div>
  );
}

/*
function HowToGetThere() {
  return (
    <>
    </>
  );
}
*/

function Contact() {
  const { t } = useTranslation();
  function ContactEntry(entryName, entryContent) {
    return (
      <div className="ContactEntry">
        <h4>{entryName}</h4>
        <p>{entryContent}</p>
      </div>
    );
  }
  return (
    <div className="Contact">
      <h3>{t("contact.title")}</h3>
      <PC>{ContactEntry(t("contact.email.title"), t("contact.email.email"))}</PC>
      <PC>{ContactEntry(t("contact.phone.title"), t("contact.phone.info"))}</PC>
      <PC>{ContactEntry(t("contact.post.title"), t("contact.post.addr"))}</PC>
    </div>
  );
}

